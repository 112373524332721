@mixin gradient-left {
	/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#b31b1b+0,ffc72c+100 */
	background: #b31b1b; /* Old browsers */
	background: -moz-linear-gradient(-45deg,  #b31b1b 0%, #ffc72c 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(-45deg,  #b31b1b 0%,#ffc72c 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(135deg,  #b31b1b 0%,#ffc72c 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#b31b1b', endColorstr='#ffc72c',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}

@mixin gradient-right {
	/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffc72c+0,b31b1b+100 */
	background: rgb(255,199,44); /* Old browsers */
	background: -moz-linear-gradient(-45deg,  rgba(255,199,44,1) 0%, rgba(179,27,27,1) 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(-45deg,  rgba(255,199,44,1) 0%,rgba(179,27,27,1) 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(135deg,  rgba(255,199,44,1) 0%,rgba(179,27,27,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffc72c', endColorstr='#b31b1b',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}

@mixin gradient-orange {
	/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#e87722+0,ffc72c+100 */
	background: rgb(232,119,34); /* Old browsers */
	background: -moz-linear-gradient(top,  rgba(232,119,34,1) 0%, rgba(255,199,44,1) 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top,  rgba(232,119,34,1) 0%,rgba(255,199,44,1) 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom,  rgba(232,119,34,1) 0%,rgba(255,199,44,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e87722', endColorstr='#ffc72c',GradientType=0 ); /* IE6-9 */
}

@mixin gradient-orange-bright {
	/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#e87722+0,ffc72c+100&0.33+0,0.33+100 */
	background: -moz-linear-gradient(top,  rgba(232,119,34,0.33) 0%, rgba(255,199,44,0.33) 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top,  rgba(232,119,34,0.33) 0%,rgba(255,199,44,0.33) 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom,  rgba(232,119,34,0.33) 0%,rgba(255,199,44,0.33) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#54e87722', endColorstr='#54ffc72c',GradientType=0 ); /* IE6-9 */
}