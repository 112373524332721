$primary-nav-width: 16.66667%;

#primary-nav .level-1 {
    width: $primary-nav-width;
    padding: 8px 2px;

    @include breakpoint($sm) {
    	width: 16.5%;

    	&:nth-child(4) {
    		width: 17.4%;
    	}
    }

    @include breakpoint($md) {
    	width: $primary-nav-width;

    	&:nth-child(4) {
    		width: $primary-nav-width;
    	}
    }
}

.brand__lockup img {
    height: 84px;

}

.title-bar {
    font-family: $wcm-regular;
    padding-top: 5px;
    @include breakpoint($xs) {
        font-size: 18px;
        // line-height: 2;
    }
}

.block-menu-block {
    @include breakpoint($md) {
        -moz-column-count: 3;
        -webkit-column-count: 3;
        -ms-column-count: 3;
        -o-column-count: 3;
        column-count: 3;
        -webkit-column-gap: 15px;
        -moz-column-gap: 15px;
        -ms-column-gap: 15px;
        -o-column-gap: 15px;
        column-gap: 15px;
        margin-bottom: 1em;
    }
}

/* =Default Select Elements
----------------------------------------------------------*/

.form-select {
	max-width: none;
	height: 50px;
	border: 1px solid $wcm-border-gray;
	box-shadow: none;
	appearance: none;
	background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath d='M207 381.5L12.7 187.1c-9.4-9.4-9.4-24.6 0-33.9l22.7-22.7c9.4-9.4 24.5-9.4 33.9 0l154.7 154 154.7-154c9.4-9.3 24.5-9.3 33.9 0l22.7 22.7c9.4 9.4 9.4 24.6 0 33.9L241 381.5c-9.4 9.3-24.6 9.3-34 0z' fill='%23cf4520'/%3E%3C/svg%3E");
	  background-repeat: no-repeat;
	  background-position: right 15px top 50%;
	background-size: 20px 20px;
	padding-right: 50px;
  }

hr {
    width: 100%;
}

.webform-client-form {
	.form-select {
		max-width: 600px;
	}
}

/*.select2 {
	padding-right: 30px;
}*/

.column-gradients {
	position: absolute;
	width: 100%;
	height: 100%;
}
.column-gradient {

	@include gradient-left;

	@include breakpoint($md) {
		width: 49.5%;
		// width: 50%;
		float: left;
		height: 100%;

	}
	height: 50%;

	margin-bottom: 10px;
	@include breakpoint($md) {
		margin-bottom: 0px;
	}

	&:first-child {
		@include gradient-right;
	}
	&:last-child {
		@include breakpoint($md) {
			float: right;
		}
	}
}


.front {
	.hero-section {
		padding-top: 10px;
		margin-bottom: 20px;
		background: $wcm-bg-gray;

		.col-md-12 {
			padding-left: 0;
			padding-right: 0;

			display: table;
		}

		.hero-section-left {

			@include breakpoint($md){
				display: table-cell;
				vertical-align: middle;
				float: none;
			}

			.hero-section-content {
				margin-bottom: 20px;
				@include breakpoint($md){
					/*position: relative;
					// top: 50%;
					margin-bottom: 0;
					transform: translateY(50%);*/
				}
			}

			.appt-callout {
				color: $wcm-dark-gray;
				font-family: $wcm-regular;
				font-size: 18px;
				margin-top: 40px;

				@include breakpoint($xs) {
					font-size: 16px;
					margin-top: 20px;
				}
			}

			.phone-callout {
				font-family: $wcm-bold;
				font-size: 30px;
				@include breakpoint($xs) {
					font-size: 22px;
				}
				color: $wcm-red;

				a {
					border-bottom: none;
				}

				.cta-icon {
					color: $wcm-dark-orange;
					fill: $wcm-bright-orange;
					width: 75px;
					height: 50px;
					position: relative;
					top: 15px;
					border-left: 1px solid $wcm-border-gray;
					padding-left: 25px;
					margin-left: 15px;

					@include breakpoint($xs) {
						width: 60px;
						height: 35px;
						top: 10px;
					}
				}
			}

			.fieldable-panels-pane {
				font-size: 22px;
				@include breakpoint($xs) {
					font-size: 20px;
				}
				font-family: $wcm-regular;
				color: $wcm-med-gray;

				a {
					font-size: 18px;

					@include breakpoint($xs) {
						font-size: 16px;
					}

					&:after {
						content: '\e80d';
						font-size: 60%;
						padding-left: 10px;
						@include fontello();
						text-decoration: none;
					}
				}
			}
		}

		.hero-section-right {
			text-align: center;

			@include breakpoint($md){
				display: table-cell;
				vertical-align: middle;
				float: none;
			}

			@include breakpoint($xs) {
				img {
					width: 40%;
				}
			}
		}
	}

	.middle-content-bottom {
		padding: 20px;
		display: table;

		.col-md-7 {

			@include breakpoint($md){
				display: table-cell;
				vertical-align: middle;
				float: none;
			}

			@include breakpoint($xs) {
				text-align: center;
				margin-bottom: 20px;
				img {
					width: 40%;
				}
			}
		}
		.col-md-5 {
			font-size: 22px;
			font-family: $wcm-regular;
			color: $wcm-med-gray;

			@include breakpoint($md){
				display: table-cell;
				vertical-align: middle;
				float: none;
			}

			.middle-content-right {
				@include breakpoint($md){
					// position: relative;
					// transform: translateY(50%);
				}

				@include breakpoint($xs) {
					font-size: 20px;
				}
			}

			.wcc-connect {
				margin-top: 30px;
				font-family: $wcm-bold;
				color: $wcm-red;

				span {
					color: $wcm-bright-orange;
				}

				.cta-icon {
					color: $wcm-dark-orange;
					fill: $wcm-bright-orange;
					width: 50px;
					height: 50px;
					position: relative;
					top: 10px;
					margin-right: 15px;
				}

				@include breakpoint($xs) {
					margin-top: 10px;

					font-size: 18px;

					.cta-icon {
						width: 30px;
						height: 30px;
						top: 5px;
						margin-right: 10px;
					}
				}
			}

			a {
				text-decoration: none;
				border-bottom: none;
				&:after {
					content: '';
				}
			}
		}
	}

	/*************************/

	.bottom-columns {

		.row {
			position: relative;
		}

		.column-gradients {
			/*.bg-image {
				position: absolute;
				width: 45%;
				height: 100%;
				background-image: url(../images/research-image-home.png);
				background-repeat: no-repeat;
				background-position: top right;
				opacity: 0.5;
			}*/

			.column-gradient.left-gradient {
				@include gradient-right;
			}
		}

		.col-md-12 {
			@include breakpoint($xs) {
				padding-left: inherit;
				padding-right: inherit;
			}

			a {
				text-decoration: none;
			}
		}
		.col-md-6.bottom-column-left {
			// height: 300px;
			overflow: hidden;
			@include gradient-right;
			// padding-bottom: 0;
			@include breakpoint($md) {
				border-right: 10px solid $wcm-white;
				// padding-bottom: 10px;
			}

			@include transition(all 0.25s);

			.bg-image {
				position: absolute;
				width: 100%;
				height: 100%;
				background-image: url(../images/research-image-home.png);
				background-repeat: no-repeat;
				background-position: top right;
				opacity: 0.6;
				right: 100px;
				top: -30%;
				// top: -150px;

				mix-blend-mode: multiply;

			    &.opacity-mode {
			    	opacity: 0.2;
			    }

			    @include breakpoint($sm) {
			    	background-size: 33%;
			    }
			    @include breakpoint($md) {
			    	background-size: inherit;
			    }
			}

			.opacity {
				@include transition(all 0.25s);
				background: rgba(255,255,255,0.5);
			    position: absolute;
			    width: 100%;
			    left: 0;
			    top: 0;
			    height: 100%;
			    z-index: 1;
			    /*@include breakpoint($md){
			    	opacity: 0;
				}*/
			}

			.pane-bundle-text {
				padding: 40px 0px;
				height: 300px;
				@include breakpoint($md) {
					width: 70%;
					height: 500px;
				}

				@include breakpoint($xs) {
					padding: 40px 20px;
					height: 360px;
				}
				// margin: 0 auto;
				margin: 0 auto 0 0;
				position: relative;
				z-index: 2;
				// @include v-center;

				.pane-title, h3 {
					@include transition(all 0.25s);
					font-size: 18px;
					font-family: inherit;
					margin-top: 0;
					color: $wcm-med-gray;
					padding-bottom: 10px;
					@include breakpoint($md){
						// padding-bottom: 0px;
						// font-size: 36px;
						// font-family: $wcm-bold;

						// font-family: inherit;
						font-size: 20px;
						// color: #333333;
						// padding-bottom: 10px;
					}
				}
				.fieldable-panels-pane {
					@include v-center;
				}
				.fieldable-panels-pane p {
					// @include transition(all 0.25s);

					@include breakpoint($md){
						// display: none;
						// visibility: hidden;
						font-size: 26px;
						line-height: 1.5;
						// max-height: 0;
						// overflow: hidden;
					}
					font-size: 20px;
					color: $wcm-dark-orange;
				}
			}

			&:hover {
				/*.opacity {
					opacity: 1;
				}*/
				/*.pane-title, h3 {
					font-family: inherit;
					font-size: 20px;
					color: $wcm-dark-gray;
					padding-bottom: 10px;
				}
				.fieldable-panels-pane p {
					max-height: 400px;
				}*/
			}
		} // col-md-6

		.bottom-column-right {
			@include gradient-left;

			padding-left: 0;
			padding-right: 0;

			@include breakpoint($md) {
				border-left: 10px solid $wcm-white;
				padding-right: inherit;
			}
		}

		.bottom-block-right {
			// background: $wcm-yellow;
			// @include gradient-right;
			background: rgba(255, 194, 124, 0.75);
			@include breakpoint($xs) {
				padding: 60px 40px 0 40px;
			}
			padding: 70px 60px 0 60px;
			height: 270px;
			border-bottom: 20px solid $wcm-white;

			h3 {
				font-family: inherit;
				font-size: 20px;
				color: $wcm-med-gray;
				padding-bottom: 10px;

				font-family: inherit;
				margin-top: 0;
				@include breakpoint($md){
				}
			}

			p {
				@include breakpoint($md){
					font-size: 26px;
					line-height: 1.5;
				}
				font-size: 20px;
				color: $wcm-dark-orange;
			}
		}

		.bottom-block-right-bottom {
			position: relative;
			background: $wcm-white;

			.bottom-right-bg {
				position: absolute;
				width: 100%;
				height: 100%;
				background: url(../images/people-image-home.png) no-repeat bottom right;
				background-size: 30%;
				background-position: 100% -100%;

				@include breakpoint($sm) {
					background-position: 100% 110%;
				}

				@include breakpoint($xs) {
					background-position: 100% 110%;
				}
			}

			.right-bottom-content {
				position: relative;
				z-index: 1;
				@include gradient-orange-bright;
				// background: url(../images/people-image-home.png) no-repeat right;
				@include breakpoint($xs) {
					padding: 50px 40px;

				}
				padding: 50px 60px;

				height: 230px;
				h3 {
					font-size: 36px;
					color: $wcm-dark-orange;
					// font-family: $font-family-sans-serif;
					margin-top: 0;
					margin-bottom: 10px;
					// text-shadow: 0 0 5px #ffc72c;

					@include breakpoint($xs) {
						font-size: 20px;
					}
				}

				p, a {
					font-size: 16px;
					&:after {
						content: '\e80d';
						font-size: 80%;
						padding-left: 10px;
						@include fontello();
					}
				}
			}
		}
	}
}

body:not(.panels-ipe-editing) {

	&.front {
		.hero-image--full {
		    width: 100%;
		    // height: 175px;
		    background-repeat: no-repeat;
		    background-size: contain;
		    background-position: bottom right;
		    // margin: 0 0 20px;
		    position: absolute;
		    // mix-blend-mode: luminosity;
		    mix-blend-mode: soft-light;
		    left: 0;

		    &.opacity-mode {
		    	opacity: 0.4;
		    }
		    height: 100%;
		    // height: 400px;
		}
	}

.column-spacer {
	height: 20px;
	@include breakpoint($md) {
		float: left;
	}
}
.wcm-thalassemia-home {

	.middle-columns {

		.row {
			position: relative;
		}

		.column-gradients {
			@include breakpoint($xs) {
				display: none;
			}
		}

		.col-md-12 {
			padding-left: 0;
			padding-right: 0;
			@include breakpoint($xs) {
				padding-left: inherit;
				padding-right: inherit;
			}

			a {
				text-decoration: none;
			}
		}
		// &:not(.panels-ipe-editing) {
		.col-md-6 {
			// &:not(.panels-ipe-editing) * {
			// min-height: 400px;
			// max-height: 500px;
			padding-bottom: 0;
			/*@include breakpoint($md) {
				padding-bottom: 10px;
			}*/

			// @include breakpoint($xs) {
				// margin-bottom: 20px;
			// }

			@include transition(all 0.25s);

			&.middle-column-left {
				// @include breakpoint($xs) {
					@include gradient-right;
				// }
				@include breakpoint($md) {
					border-right: 10px solid $wcm-white;
					padding-right: 0;
				}

				.opacity-bg {
					width: 100%;
					background: url(../images/for-patients-image.png);
				    background-repeat: no-repeat;
				    background-size: contain;
				    background-position: bottom right;
				    position: absolute;
				    mix-blend-mode: soft-light;
				    left: 0;

				    &.opacity-mode {
				    	opacity: 0.4;
				    }
				    height: 300px;
				    @include breakpoint($md) {
				    	height: 500px;
					}
				}
			}

			&.middle-column-right {
				// @include breakpoint($xs) {
					@include gradient-left;
				// }
				@include breakpoint($md) {
					border-left: 10px solid $wcm-white;
					padding-left: 0;
				}

				.hero-image--full {
					background-position: bottom left;
				}

				.pane-bundle-text {
					margin: 0 0 0 auto;
					// text-align: center;
				}

				.opacity-bg {
					width: 100%;
					background: url(../images/for-providers-image.png);
				    background-repeat: no-repeat;
				    background-size: contain;
				    background-position: bottom left;
				    position: absolute;
				    mix-blend-mode: soft-light;
				    left: 0;

				    &.opacity-mode {
				    	opacity: 0.4;
				    }
				    height: 300px;
				    @include breakpoint($md) {
				    	height: 500px;
					}
				}
			}

			.opacity {
				@include transition(all 0.25s);
				background: rgba(255,255,255,0.5);
			    position: absolute;
			    width: 100%;
			    left: 0;
			    top: 0;
			    height: 100%;
			    z-index: 1;
			    @include breakpoint($md){
			    	opacity: 0;
				}
			}

			.col-md-6-inner-left, .col-md-6-inner-right {
				width: auto;
			}

			.pane-bundle-text {
				padding: 40px 0px;
				height: 300px;
				// text-align: center;
				@include breakpoint($md) {
					width: 60%;
					height: 500px;
				}
				margin: 0 auto 0 0;
				position: relative;
				z-index: 2;
				// @include v-center;

				.pane-title, h3 {
					color: #333333;
					@include transition(all 0.25s);
					font-size: 18px;
					font-family: inherit;
					margin-top: 0;
					// text-align: center;
					color: $wcm-dark-gray;
					padding-bottom: 10px;
					@include breakpoint($md){
						color: $wcm-white;
						// margin-top: 0;
						padding-bottom: 0;
						font-size: 36px;
						font-family: $wcm-bold;
					}
				}
				.fieldable-panels-pane {
					@include v-center;
				}
				.fieldable-panels-pane p {
					@include transition(all 0.25s);

					@include breakpoint($md){
						font-size: 26px;
						line-height: 1.5;
						max-height: 0;
						overflow: hidden;
					}
					font-size: 20px;
					color: $wcm-dark-orange;
				}
			}

			&:hover {
				.opacity {
					opacity: 1;
				}
				.pane-title, h3 {
					font-family: inherit;
					font-size: 20px;
					color: $wcm-dark-gray;
					padding-bottom: 10px;
				}
				.fieldable-panels-pane p {
					max-height: 1000px;
					// max-height: 400px;
				}
			}
			// }
		} // col-md-6
	}
}
}

.pane-news-panel-pane-3 {
	@include clearfix();
	background: $wcm-bg-gray;

	padding: 20px 0;

	.pane-title {
		text-align: center;
		color: $wcm-med-gray;
		padding-bottom: 30px;
	}

	.view-content {
		margin: 0 auto;
		max-width: 1140px;

		.teaser-image {
			margin-bottom: 20px;
		}

		.teaser-title {
			font-family: inherit;
			a {
				&:after {
					content: '';
				}
			}
		}

		.views-field-created {
			&:after {
				content: '';
				position: relative;
				width: 70px;
				display: block;
				margin: 10px auto;
				border-bottom: 2px solid $wcm-med-gray;
			}
		}
	}

	.view-footer {
		padding-top: 20px;
		clear: both;
		text-align: center;

		a {
			&:after {
				content: '\e80d';
				font-size: 80%;
				padding-left: 10px;
				@include fontello();
			}
		}
	}
}

.view-news {
	.view-teaser {

		border-bottom: 1px solid $wcm-border-gray;
    	padding-bottom: 15px;

    	.front & {
    		border-bottom: none;
    		text-align: center;
    	}

		h2.teaser-title {
			font-size: 20px;
			line-height: 1.4;
		}

		.post-date {
			color: $wcm-med-gray;
			font-family: $wcm-bold;
			text-transform: uppercase;
			margin-top: 5px;
			font-size: 13px;
			letter-spacing: 2px;
		}
	}
}

.node-type-news-post {
	.post-date {
		color: $wcm-med-gray;
			font-family: $wcm-bold;
			text-transform: uppercase;
			font-size: 13px;
			letter-spacing: 2px;
	}
}
/*
.social-share {
  // margin: 15px 0;
  padding: 0;
}
.social-share li {
	display: inline-block;
	height: 30px;
	padding: 0 5px;
	font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0;
}

.social-share a {
  color: $wcm-bright-orange;
  &:before {
    font-size: 16px;
    @include fontello();
    @include v-center;
  }
  &:hover {
    color: $wcm-yellow;
    text-decoration: none;
  }
}

.social-share__facebook a {
  &:before {
    content:'\e804';
  }
}

.social-share__twitter a {
  &:before {
    content:'\e807';
  }
}

.social-share__linkedin a {
  &:before {
    content:'\e806';
  }
}
//*/

.field-name-field-news-category, .views-field-field-news-category {
    a {
        color: $wcm-dark-orange;
        font-size: 14px;
        background: url(../images/tag.png) 0 0 no-repeat;
        background-size: 16px 16px;
        padding-left: 25px;
        position: relative;
    }
}


.teaser-image img.panopoly-image-quarter {
    float: none;
    margin: 0 0 0.25em 0;
    width: auto;
    max-width: none;
    @include breakpoint($sm) {
        float: right;
        margin: 0.25em 0 1.3em 1em;
        width: 25%;
        max-width: 25%;
    }
}

.view-guidelines {
	.views-row {
		// border-bottom: 1px solid $wcm-border-gray;
		// padding: 15px 55px 15px 10px;

		.views-field-field-guideline-pdf {
			position: relative;
			border-bottom: 1px solid $wcm-border-gray;
			padding: 15px 0px 15px 0px;

			.file-icon {
				display: none;
			}
			a {
				color: $wcm-black;
				display: block;
				position: relative;
				z-index: 1;
				&:hover {
					color: $wcm-bright-orange;
				}
			}

			.cta-icon {
				color: $wcm-dark-orange;
				fill: $wcm-bright-orange;
				width: 45px;
				height: 30px;
				position: absolute;
				right: 0;
				top: 15px;
				border-left: 1px solid $wcm-border-gray;
				padding-left: 15px;
			}
		}

		.views-field-field-guideline-link {
			position: relative;
			border-bottom: 1px solid $wcm-border-gray;
			padding: 15px 0px 15px 0px;

			a {
				color: $wcm-black;
				display: block;
				position: relative;
				z-index: 1;
				&:after {
					display: none;
				}
				&:hover {
					color: $wcm-bright-orange;
				}
			}
			&:after {
				content: '\e811';
				position: absolute;
				right: 0;
				top: 15px;
				// width: 50px;
				font-size: 30px;
				color: $wcm-dark-orange;
				@include fontello();
				border-left: 1px solid $wcm-border-gray;
				padding-left: 15px;
			}
		}
	}
}

.view-education-material {
	margin-top: 40px;

	.views-row {
		border-bottom: 1px solid $wcm-border-gray;
		padding-bottom: 20px;
	}
	.education_material_post_info {
		color: $wcm-med-gray;
		font-family: $wcm-bold;
		text-transform: uppercase;
		margin-top: 5px;
		font-size: 13px;
		letter-spacing: 2px;

		.author-name {
			font-size: 15px;
			text-transform: none;
			font-family: inherit;
		}
		/*.post-date {
			color: $wcm-med-gray;
			font-family: $wcm-bold;
			text-transform: uppercase;
			margin-top: 5px;
			font-size: 13px;
			letter-spacing: 2px;
		}*/
	}
	.views-field-field-education-material-pdf {
		.file-icon {
			display: none;
		}
		a:before {
			content: '';
			display: inline-block;
		    background-repeat: no-repeat;
		    // background: url(/profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/sprite/sprite.svg);
		    background: url(../../../../../../profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/sprite/sprite.svg);
		    width: 30px;
		    height: 30px;
		    background-position: -37px -113px;
		    // background-size: 1900%;
		    // calculation: sprite site: 1264px 304px
		    // desired size = 30 / original size= 64
		    // bg width = 1264 * 0.46875
		    // bg height = 304 * 0.46875
		    background-size: 592px 143px;
		    margin-right: 5px;
		}
	}
}

.view-webinars {
	margin-top: 40px;

	.views-row {
		border-bottom: 1px solid $wcm-border-gray;
		padding-bottom: 20px;

		.views-field-field-webinar-date {
			color: $wcm-med-gray;
			font-family: $wcm-bold;
			text-transform: uppercase;
			margin-top: 5px;
			font-size: 13px;
			letter-spacing: 2px;
		}
	}
}

.pane-profiles-panel-pane-3 {
	.view-profiles {
		margin-top: 40px;

		.views-row {
			border-bottom: 1px solid $wcm-border-gray;
			padding-bottom: 20px;
			padding-top: 20px;

			.col-md-2 {
				padding-left: 0;
				// padding-right: 0;
			}

			.col-md-2-no-image + .col-md-10 {
				padding-left: 0;
			}

			/*.col-md-10 {
				padding-left: 0;
			}*/

			.display-name {
				font-size: 15px;
				margin-top: 0;
			}

			.views-field-field-biographical-info {
				padding-top: 20px;
			}
		}
	}
}

.pane-wcmc-second-level-nav {
	clear: both;
}

.view-clinical-trials {

	.pane-clinical-trials-panel-pane-2 & {
		.views-row {
			border-bottom: none;
			padding-bottom: 0;
		}
	}
	.views-row {

		border-bottom: 1px solid $wcm-border-gray;
		padding-bottom: 20px;
		margin-bottom: 15px;

		.views-field-title, .trial-title {
			padding-bottom: 10px;
			font-size: 17px;
		}

		.views-field-field-trial-primary-investigator,
		.views-field-field-trial-contact-email,
		.views-field-field-trial-contact-name,
		.views-field-field-trial-contact-phone,
		.views-field {
			padding-bottom: 2px;
			.views-label {
				font-weight: 600;
			}
			.field-content {
				display: inline;
			}
		}

		.views-field-nothing {
			padding-top: 10px;
		}
	}

	a.pc-btn-small {
		display: inline-block;
    	background-color: $wcm-dark-orange;
	    border-radius: 7px;
	    height: 30px;
	    line-height: 30px;
	    padding: 0 14px;
	    color: #fff;
	    margin-right: 10px;
	    font-size: 13px;
	}
}

.page-research-publications {

    &.page-research-publications-posters {
        h1.title {
            padding-left: 0 !important;
            padding-right: 0 !important;
        }
    }

    .site-main {
        margin-left: auto;
        margin-right: auto;
//        padding-left: 20px;
//        padding-right: 20px;

        h1.title, .biblio-category-section, #biblio-header {
            padding-left: 20px;
            padding-right: 20px;
        }


        #biblio-header {
            a {
                padding-left: 10px;
                padding-right: 10px;
                &:first-child {
                    padding-left: 0;
                }
            }
            img {
                margin-left: -10px;
                padding-right: 10px;
                vertical-align: middle;
            }
        }

        @include breakpoint($sm) {
            width: 768px;
        }
        @include breakpoint($md) {
            width: 980px;
        }
        @include breakpoint($lg) {
            width: 1180px;
        }
    }
}

.pager--infinite-scroll {
    li a {
        border-radius: 20px;
        border: 4px solid $wcm-bright-orange;
        padding: 6px 20px;
        font-size: 13px;
        width: 220px;

        &:hover, &:focus {
            border-color: $wcm-dark-orange;
            background-color: $wcm-bright-orange;
            color: #fff;
        }
    }
}

.view-testimonials {
	clear: both;
	.views-row {
		@include clearfix();
		border-bottom: 1px solid $wcm-border-gray;
		padding-bottom: 20px;
		margin-bottom: 20px;

		&:last-child {
			border-bottom: none;
		}

		.no-image + .col-sm-8 {
			width: 100%;
			padding-left: 0;
		}

		.col-sm-4 {
			margin-bottom: 15px;
			padding-left: 0;

			@include breakpoint($xs) {
				padding-right: 0;
			}
			img {
				margin-bottom: 5px;
				@include breakpoint($sm) {
					width: 100%;
				}
			}

			figcaption {
			    text-align: left;
			    float: none;
			    width: auto;
			}
		}

		.col-sm-8 {
			padding-right: 0;
			@include breakpoint($xs) {
				padding-left: 0;
			}
		}
	}
}

.pane-bundle-spotlight {
	.panopoly-image-spotlight {
		width: 100%;
	}
}

.wcm-callout-link:hover {
	text-decoration: none;
}
.wcm-callout {
    position: relative;

    margin-top: 30px;
    margin-bottom: 30px;

    .wcm-callout-title {
        padding: 50px 20px 50px;
        text-transform: uppercase;
        // font-size: 50px;
        // line-height: 50px;
        // border: 2px solid $wcm-white;
        // @include center();
        // background: rgba(0,0,0, 0.3);
        color: $wcm-white;
        // font-family: $wcm-bold;
        text-align: center;
        z-index: 10;
        font-weight: 600;
        letter-spacing: 2px;

        @include breakpoint($xs) {
        	padding: 30px 20px 30px;
            // font-size: 30px;
            // line-height: 40px;
        }
    }

    &.wcm-red {
       background: $wcm-red;

        &:hover {
            background: $wcm-bright-orange;
        }
    }

    &.wcm-dark-orange {
        background: $wcm-dark-orange;

        &:hover {
            background: $wcm-bright-orange;
        }
    }
    &.wcm-bright-orange {
        background: $wcm-bright-orange;
        &:hover {
            background: $wcm-yellow;
        }
    }
    &.wcm-yellow {
        background: $wcm-yellow;
        &:hover {
            background: $wcm-bright-orange;
        }
    }

}

.footer-contact__contact-us a {
    @include breakpoint($sm-only) {
        padding: 20px 20px;
    }
    /*@include breakpoint($md) {
        padding: 20px 28px;
    }*/
}

.media-gallery-callout {
  clear: both;
  border-top: 1px solid #eee;
}

#drawer-nav {
  ul {
    li.level-1:nth-child(4) {
      ul {
        li.level-2 {
          @include breakpoint($sm) {
            padding-right: 15px;
          }
          @include breakpoint($md) {
            padding-right: 10px;
          }
        }
      }
    }
  }
}

#webform-client-form-62 {
  fieldset {
    border: 0;
    border-radius: 0;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    margin-bottom: 0;
    .panel-body {
      padding: 5px 0 0 0;
    }
  }
}
