/**
 * Example Stub for creating WCMC Subthemes
 *
 */
/**
 * This file is used to customize the default Bootstrap core variables.
 * A list of options can be found here:
 * https://github.com/twbs/bootstrap-sass/blob/3.2-stable/assets/stylesheets/bootstrap/_variables.scss
 */
/* =WCM Colors and Fonts
----------------------------------------------------------*/
/* !WARNING!
When changing the colors below also change them in:
profiles/wcmc/modules/custom/panopoly_override/features/panopoly_wysiwyg.inc:53,
inside the panopoly_override_wysiwyg_editor_settings_alter() function.
JIRA Ticket: WEBENG-843
*/
/* =Scaffolding
----------------------------------------------------------*/
/* =Typography
----------------------------------------------------------*/
/* =Grid System
Padding between columns. Gets divided in half for the left and right.
----------------------------------------------------------*/
/* =Buttons
Define text, background and border color.
----------------------------------------------------------*/
/* =Forms
----------------------------------------------------------*/
/* =Pagination
----------------------------------------------------------*/
/**
 * Custom Mixins
 *
 */
/* =Breakpoint Utility
// https://medium.com/developing-with-sass/refactoring-my-simple-sass-breakpoint-mixin-71205dd6badd
----------------------------------------------------------*/
/* =Container Wrap
This is used when you can't add the class="container" markup,
just use this mixin on a wrapping element instead.
----------------------------------------------------------*/
/* =Fontello Icon Font
----------------------------------------------------------*/
/* =Dead Center within parent
----------------------------------------------------------*/
/* =Vertically Center
----------------------------------------------------------*/
/* =Button SubNav Style
----------------------------------------------------------*/
/* =Site-specific styles
----------------------------------------------------------*/
#primary-nav .level-1 {
  width: 16.66667%;
  padding: 8px 2px; }
  @media screen and (min-width: 768px) {
    #primary-nav .level-1 {
      width: 16.5%; }
      #primary-nav .level-1:nth-child(4) {
        width: 17.4%; } }
  @media screen and (min-width: 992px) {
    #primary-nav .level-1 {
      width: 16.66667%; }
      #primary-nav .level-1:nth-child(4) {
        width: 16.66667%; } }

.brand__lockup img {
  height: 84px; }

.title-bar {
  font-family: "1898Sans-Regular", sans-serif;
  padding-top: 5px; }
  @media screen and (max-width: 767px) {
    .title-bar {
      font-size: 18px; } }

@media screen and (min-width: 992px) {
  .block-menu-block {
    -moz-column-count: 3;
    -webkit-column-count: 3;
    -ms-column-count: 3;
    -o-column-count: 3;
    column-count: 3;
    -webkit-column-gap: 15px;
    -moz-column-gap: 15px;
    -ms-column-gap: 15px;
    -o-column-gap: 15px;
    column-gap: 15px;
    margin-bottom: 1em; } }

/* =Default Select Elements
----------------------------------------------------------*/
.form-select {
  max-width: none;
  height: 50px;
  border: 1px solid #dddddd;
  box-shadow: none;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath d='M207 381.5L12.7 187.1c-9.4-9.4-9.4-24.6 0-33.9l22.7-22.7c9.4-9.4 24.5-9.4 33.9 0l154.7 154 154.7-154c9.4-9.3 24.5-9.3 33.9 0l22.7 22.7c9.4 9.4 9.4 24.6 0 33.9L241 381.5c-9.4 9.3-24.6 9.3-34 0z' fill='%23cf4520'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 15px top 50%;
  background-size: 20px 20px;
  padding-right: 50px; }

hr {
  width: 100%; }

.webform-client-form .form-select {
  max-width: 600px; }

/*.select2 {
	padding-right: 30px;
}*/
.column-gradients {
  position: absolute;
  width: 100%;
  height: 100%; }

.column-gradient {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#b31b1b+0,ffc72c+100 */
  background: #b31b1b;
  /* Old browsers */
  background: -moz-linear-gradient(-45deg, #b31b1b 0%, #ffc72c 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(-45deg, #b31b1b 0%, #ffc72c 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(135deg, #b31b1b 0%, #ffc72c 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#b31b1b', endColorstr='#ffc72c',GradientType=1 );
  /* IE6-9 fallback on horizontal gradient */
  height: 50%;
  margin-bottom: 10px; }
  @media screen and (min-width: 992px) {
    .column-gradient {
      width: 49.5%;
      float: left;
      height: 100%; } }
  @media screen and (min-width: 992px) {
    .column-gradient {
      margin-bottom: 0px; } }
  .column-gradient:first-child {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffc72c+0,b31b1b+100 */
    background: #ffc72c;
    /* Old browsers */
    background: -moz-linear-gradient(-45deg, #ffc72c 0%, #b31b1b 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(-45deg, #ffc72c 0%, #b31b1b 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(135deg, #ffc72c 0%, #b31b1b 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffc72c', endColorstr='#b31b1b',GradientType=1 );
    /* IE6-9 fallback on horizontal gradient */ }
  @media screen and (min-width: 992px) {
    .column-gradient:last-child {
      float: right; } }

.front {
  /*************************/ }
  .front .hero-section {
    padding-top: 10px;
    margin-bottom: 20px;
    background: #f7f7f7; }
    .front .hero-section .col-md-12 {
      padding-left: 0;
      padding-right: 0;
      display: table; }
    @media screen and (min-width: 992px) {
      .front .hero-section .hero-section-left {
        display: table-cell;
        vertical-align: middle;
        float: none; } }
    .front .hero-section .hero-section-left .hero-section-content {
      margin-bottom: 20px; }
      @media screen and (min-width: 992px) {
        .front .hero-section .hero-section-left .hero-section-content {
          /*position: relative;
					// top: 50%;
					margin-bottom: 0;
					transform: translateY(50%);*/ } }
    .front .hero-section .hero-section-left .appt-callout {
      color: #555555;
      font-family: "1898Sans-Regular", sans-serif;
      font-size: 18px;
      margin-top: 40px; }
      @media screen and (max-width: 767px) {
        .front .hero-section .hero-section-left .appt-callout {
          font-size: 16px;
          margin-top: 20px; } }
    .front .hero-section .hero-section-left .phone-callout {
      font-family: "1898Sans-Bold", sans-serif;
      font-size: 30px;
      color: #b31b1b; }
      @media screen and (max-width: 767px) {
        .front .hero-section .hero-section-left .phone-callout {
          font-size: 22px; } }
      .front .hero-section .hero-section-left .phone-callout a {
        border-bottom: none; }
      .front .hero-section .hero-section-left .phone-callout .cta-icon {
        color: #cf4520;
        fill: #e7751d;
        width: 75px;
        height: 50px;
        position: relative;
        top: 15px;
        border-left: 1px solid #dddddd;
        padding-left: 25px;
        margin-left: 15px; }
        @media screen and (max-width: 767px) {
          .front .hero-section .hero-section-left .phone-callout .cta-icon {
            width: 60px;
            height: 35px;
            top: 10px; } }
    .front .hero-section .hero-section-left .fieldable-panels-pane {
      font-size: 22px;
      font-family: "1898Sans-Regular", sans-serif;
      color: #666666; }
      @media screen and (max-width: 767px) {
        .front .hero-section .hero-section-left .fieldable-panels-pane {
          font-size: 20px; } }
      .front .hero-section .hero-section-left .fieldable-panels-pane a {
        font-size: 18px; }
        @media screen and (max-width: 767px) {
          .front .hero-section .hero-section-left .fieldable-panels-pane a {
            font-size: 16px; } }
        .front .hero-section .hero-section-left .fieldable-panels-pane a:after {
          content: '\e80d';
          font-size: 60%;
          padding-left: 10px;
          font-family: "fontello";
          font-style: normal;
          font-weight: normal;
          speak: none;
          display: inline-block;
          text-decoration: inherit;
          text-align: center;
          /* opacity: .8; */
          /* For safety - reset parent styles, that can break glyph codes*/
          font-variant: normal;
          text-transform: none;
          /* fix buttons height, for twitter bootstrap */
          line-height: 1em;
          /* Animation center compensation - margins should be symmetric */
          /* remove if not needed */
          /* you can be more comfortable with increased icons size */
          /* font-size: 120%; */
          /* Font smoothing. That was taken from TWBS */
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          /* Uncomment for 3D effect */
          /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
          text-decoration: none; }
    .front .hero-section .hero-section-right {
      text-align: center; }
      @media screen and (min-width: 992px) {
        .front .hero-section .hero-section-right {
          display: table-cell;
          vertical-align: middle;
          float: none; } }
      @media screen and (max-width: 767px) {
        .front .hero-section .hero-section-right img {
          width: 40%; } }
  .front .middle-content-bottom {
    padding: 20px;
    display: table; }
    @media screen and (min-width: 992px) {
      .front .middle-content-bottom .col-md-7 {
        display: table-cell;
        vertical-align: middle;
        float: none; } }
    @media screen and (max-width: 767px) {
      .front .middle-content-bottom .col-md-7 {
        text-align: center;
        margin-bottom: 20px; }
        .front .middle-content-bottom .col-md-7 img {
          width: 40%; } }
    .front .middle-content-bottom .col-md-5 {
      font-size: 22px;
      font-family: "1898Sans-Regular", sans-serif;
      color: #666666; }
      @media screen and (min-width: 992px) {
        .front .middle-content-bottom .col-md-5 {
          display: table-cell;
          vertical-align: middle;
          float: none; } }
      @media screen and (max-width: 767px) {
        .front .middle-content-bottom .col-md-5 .middle-content-right {
          font-size: 20px; } }
      .front .middle-content-bottom .col-md-5 .wcc-connect {
        margin-top: 30px;
        font-family: "1898Sans-Bold", sans-serif;
        color: #b31b1b; }
        .front .middle-content-bottom .col-md-5 .wcc-connect span {
          color: #e7751d; }
        .front .middle-content-bottom .col-md-5 .wcc-connect .cta-icon {
          color: #cf4520;
          fill: #e7751d;
          width: 50px;
          height: 50px;
          position: relative;
          top: 10px;
          margin-right: 15px; }
        @media screen and (max-width: 767px) {
          .front .middle-content-bottom .col-md-5 .wcc-connect {
            margin-top: 10px;
            font-size: 18px; }
            .front .middle-content-bottom .col-md-5 .wcc-connect .cta-icon {
              width: 30px;
              height: 30px;
              top: 5px;
              margin-right: 10px; } }
      .front .middle-content-bottom .col-md-5 a {
        text-decoration: none;
        border-bottom: none; }
        .front .middle-content-bottom .col-md-5 a:after {
          content: ''; }
  .front .bottom-columns .row {
    position: relative; }
  .front .bottom-columns .column-gradients {
    /*.bg-image {
				position: absolute;
				width: 45%;
				height: 100%;
				background-image: url(../images/research-image-home.png);
				background-repeat: no-repeat;
				background-position: top right;
				opacity: 0.5;
			}*/ }
    .front .bottom-columns .column-gradients .column-gradient.left-gradient {
      /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffc72c+0,b31b1b+100 */
      background: #ffc72c;
      /* Old browsers */
      background: -moz-linear-gradient(-45deg, #ffc72c 0%, #b31b1b 100%);
      /* FF3.6-15 */
      background: -webkit-linear-gradient(-45deg, #ffc72c 0%, #b31b1b 100%);
      /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(135deg, #ffc72c 0%, #b31b1b 100%);
      /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffc72c', endColorstr='#b31b1b',GradientType=1 );
      /* IE6-9 fallback on horizontal gradient */ }
  @media screen and (max-width: 767px) {
    .front .bottom-columns .col-md-12 {
      padding-left: inherit;
      padding-right: inherit; } }
  .front .bottom-columns .col-md-12 a {
    text-decoration: none; }
  .front .bottom-columns .col-md-6.bottom-column-left {
    overflow: hidden;
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffc72c+0,b31b1b+100 */
    background: #ffc72c;
    /* Old browsers */
    background: -moz-linear-gradient(-45deg, #ffc72c 0%, #b31b1b 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(-45deg, #ffc72c 0%, #b31b1b 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(135deg, #ffc72c 0%, #b31b1b 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffc72c', endColorstr='#b31b1b',GradientType=1 );
    /* IE6-9 fallback on horizontal gradient */
    -webkit-transition: all 0.25s;
    -o-transition: all 0.25s;
    transition: all 0.25s; }
    @media screen and (min-width: 992px) {
      .front .bottom-columns .col-md-6.bottom-column-left {
        border-right: 10px solid #fff; } }
    .front .bottom-columns .col-md-6.bottom-column-left .bg-image {
      position: absolute;
      width: 100%;
      height: 100%;
      background-image: url(../images/research-image-home.png);
      background-repeat: no-repeat;
      background-position: top right;
      opacity: 0.6;
      right: 100px;
      top: -30%;
      mix-blend-mode: multiply; }
      .front .bottom-columns .col-md-6.bottom-column-left .bg-image.opacity-mode {
        opacity: 0.2; }
      @media screen and (min-width: 768px) {
        .front .bottom-columns .col-md-6.bottom-column-left .bg-image {
          background-size: 33%; } }
      @media screen and (min-width: 992px) {
        .front .bottom-columns .col-md-6.bottom-column-left .bg-image {
          background-size: inherit; } }
    .front .bottom-columns .col-md-6.bottom-column-left .opacity {
      -webkit-transition: all 0.25s;
      -o-transition: all 0.25s;
      transition: all 0.25s;
      background: rgba(255, 255, 255, 0.5);
      position: absolute;
      width: 100%;
      left: 0;
      top: 0;
      height: 100%;
      z-index: 1;
      /*@include breakpoint($md){
			    	opacity: 0;
				}*/ }
    .front .bottom-columns .col-md-6.bottom-column-left .pane-bundle-text {
      padding: 40px 0px;
      height: 300px;
      margin: 0 auto 0 0;
      position: relative;
      z-index: 2; }
      @media screen and (min-width: 992px) {
        .front .bottom-columns .col-md-6.bottom-column-left .pane-bundle-text {
          width: 70%;
          height: 500px; } }
      @media screen and (max-width: 767px) {
        .front .bottom-columns .col-md-6.bottom-column-left .pane-bundle-text {
          padding: 40px 20px;
          height: 360px; } }
      .front .bottom-columns .col-md-6.bottom-column-left .pane-bundle-text .pane-title, .front .bottom-columns .col-md-6.bottom-column-left .pane-bundle-text h3 {
        -webkit-transition: all 0.25s;
        -o-transition: all 0.25s;
        transition: all 0.25s;
        font-size: 18px;
        font-family: inherit;
        margin-top: 0;
        color: #666666;
        padding-bottom: 10px; }
        @media screen and (min-width: 992px) {
          .front .bottom-columns .col-md-6.bottom-column-left .pane-bundle-text .pane-title, .front .bottom-columns .col-md-6.bottom-column-left .pane-bundle-text h3 {
            font-size: 20px; } }
      .front .bottom-columns .col-md-6.bottom-column-left .pane-bundle-text .fieldable-panels-pane {
        position: relative;
        top: 50%;
        -webkit-transform: translateY(-50%);
        /* Ch <36, Saf 5.1+, iOS, An =<4.4.4 */
        -ms-transform: translateY(-50%);
        /* IE 9 */
        transform: translateY(-50%);
        /* IE 10, Fx 16+, Op 12.1+ */ }
      .front .bottom-columns .col-md-6.bottom-column-left .pane-bundle-text .fieldable-panels-pane p {
        font-size: 20px;
        color: #cf4520; }
        @media screen and (min-width: 992px) {
          .front .bottom-columns .col-md-6.bottom-column-left .pane-bundle-text .fieldable-panels-pane p {
            font-size: 26px;
            line-height: 1.5; } }
    .front .bottom-columns .col-md-6.bottom-column-left:hover {
      /*.opacity {
					opacity: 1;
				}*/
      /*.pane-title, h3 {
					font-family: inherit;
					font-size: 20px;
					color: $wcm-dark-gray;
					padding-bottom: 10px;
				}
				.fieldable-panels-pane p {
					max-height: 400px;
				}*/ }
  .front .bottom-columns .bottom-column-right {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#b31b1b+0,ffc72c+100 */
    background: #b31b1b;
    /* Old browsers */
    background: -moz-linear-gradient(-45deg, #b31b1b 0%, #ffc72c 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(-45deg, #b31b1b 0%, #ffc72c 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(135deg, #b31b1b 0%, #ffc72c 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#b31b1b', endColorstr='#ffc72c',GradientType=1 );
    /* IE6-9 fallback on horizontal gradient */
    padding-left: 0;
    padding-right: 0; }
    @media screen and (min-width: 992px) {
      .front .bottom-columns .bottom-column-right {
        border-left: 10px solid #fff;
        padding-right: inherit; } }
  .front .bottom-columns .bottom-block-right {
    background: rgba(255, 194, 124, 0.75);
    padding: 70px 60px 0 60px;
    height: 270px;
    border-bottom: 20px solid #fff; }
    @media screen and (max-width: 767px) {
      .front .bottom-columns .bottom-block-right {
        padding: 60px 40px 0 40px; } }
    .front .bottom-columns .bottom-block-right h3 {
      font-family: inherit;
      font-size: 20px;
      color: #666666;
      padding-bottom: 10px;
      font-family: inherit;
      margin-top: 0; }
    .front .bottom-columns .bottom-block-right p {
      font-size: 20px;
      color: #cf4520; }
      @media screen and (min-width: 992px) {
        .front .bottom-columns .bottom-block-right p {
          font-size: 26px;
          line-height: 1.5; } }
  .front .bottom-columns .bottom-block-right-bottom {
    position: relative;
    background: #fff; }
    .front .bottom-columns .bottom-block-right-bottom .bottom-right-bg {
      position: absolute;
      width: 100%;
      height: 100%;
      background: url(../images/people-image-home.png) no-repeat bottom right;
      background-size: 30%;
      background-position: 100% -100%; }
      @media screen and (min-width: 768px) {
        .front .bottom-columns .bottom-block-right-bottom .bottom-right-bg {
          background-position: 100% 110%; } }
      @media screen and (max-width: 767px) {
        .front .bottom-columns .bottom-block-right-bottom .bottom-right-bg {
          background-position: 100% 110%; } }
    .front .bottom-columns .bottom-block-right-bottom .right-bottom-content {
      position: relative;
      z-index: 1;
      /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#e87722+0,ffc72c+100&0.33+0,0.33+100 */
      background: -moz-linear-gradient(top, rgba(232, 119, 34, 0.33) 0%, rgba(255, 199, 44, 0.33) 100%);
      /* FF3.6-15 */
      background: -webkit-linear-gradient(top, rgba(232, 119, 34, 0.33) 0%, rgba(255, 199, 44, 0.33) 100%);
      /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to bottom, rgba(232, 119, 34, 0.33) 0%, rgba(255, 199, 44, 0.33) 100%);
      /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#54e87722', endColorstr='#54ffc72c',GradientType=0 );
      /* IE6-9 */
      padding: 50px 60px;
      height: 230px; }
      @media screen and (max-width: 767px) {
        .front .bottom-columns .bottom-block-right-bottom .right-bottom-content {
          padding: 50px 40px; } }
      .front .bottom-columns .bottom-block-right-bottom .right-bottom-content h3 {
        font-size: 36px;
        color: #cf4520;
        margin-top: 0;
        margin-bottom: 10px; }
        @media screen and (max-width: 767px) {
          .front .bottom-columns .bottom-block-right-bottom .right-bottom-content h3 {
            font-size: 20px; } }
      .front .bottom-columns .bottom-block-right-bottom .right-bottom-content p, .front .bottom-columns .bottom-block-right-bottom .right-bottom-content a {
        font-size: 16px; }
        .front .bottom-columns .bottom-block-right-bottom .right-bottom-content p:after, .front .bottom-columns .bottom-block-right-bottom .right-bottom-content a:after {
          content: '\e80d';
          font-size: 80%;
          padding-left: 10px;
          font-family: "fontello";
          font-style: normal;
          font-weight: normal;
          speak: none;
          display: inline-block;
          text-decoration: inherit;
          text-align: center;
          /* opacity: .8; */
          /* For safety - reset parent styles, that can break glyph codes*/
          font-variant: normal;
          text-transform: none;
          /* fix buttons height, for twitter bootstrap */
          line-height: 1em;
          /* Animation center compensation - margins should be symmetric */
          /* remove if not needed */
          /* you can be more comfortable with increased icons size */
          /* font-size: 120%; */
          /* Font smoothing. That was taken from TWBS */
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          /* Uncomment for 3D effect */
          /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */ }

body:not(.panels-ipe-editing).front .hero-image--full {
  width: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom right;
  position: absolute;
  mix-blend-mode: soft-light;
  left: 0;
  height: 100%; }
  body:not(.panels-ipe-editing).front .hero-image--full.opacity-mode {
    opacity: 0.4; }

body:not(.panels-ipe-editing) .column-spacer {
  height: 20px; }
  @media screen and (min-width: 992px) {
    body:not(.panels-ipe-editing) .column-spacer {
      float: left; } }

body:not(.panels-ipe-editing) .wcm-thalassemia-home .middle-columns .row {
  position: relative; }

@media screen and (max-width: 767px) {
  body:not(.panels-ipe-editing) .wcm-thalassemia-home .middle-columns .column-gradients {
    display: none; } }

body:not(.panels-ipe-editing) .wcm-thalassemia-home .middle-columns .col-md-12 {
  padding-left: 0;
  padding-right: 0; }
  @media screen and (max-width: 767px) {
    body:not(.panels-ipe-editing) .wcm-thalassemia-home .middle-columns .col-md-12 {
      padding-left: inherit;
      padding-right: inherit; } }
  body:not(.panels-ipe-editing) .wcm-thalassemia-home .middle-columns .col-md-12 a {
    text-decoration: none; }

body:not(.panels-ipe-editing) .wcm-thalassemia-home .middle-columns .col-md-6 {
  padding-bottom: 0;
  /*@include breakpoint($md) {
				padding-bottom: 10px;
			}*/
  -webkit-transition: all 0.25s;
  -o-transition: all 0.25s;
  transition: all 0.25s; }
  body:not(.panels-ipe-editing) .wcm-thalassemia-home .middle-columns .col-md-6.middle-column-left {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffc72c+0,b31b1b+100 */
    background: #ffc72c;
    /* Old browsers */
    background: -moz-linear-gradient(-45deg, #ffc72c 0%, #b31b1b 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(-45deg, #ffc72c 0%, #b31b1b 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(135deg, #ffc72c 0%, #b31b1b 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffc72c', endColorstr='#b31b1b',GradientType=1 );
    /* IE6-9 fallback on horizontal gradient */ }
    @media screen and (min-width: 992px) {
      body:not(.panels-ipe-editing) .wcm-thalassemia-home .middle-columns .col-md-6.middle-column-left {
        border-right: 10px solid #fff;
        padding-right: 0; } }
    body:not(.panels-ipe-editing) .wcm-thalassemia-home .middle-columns .col-md-6.middle-column-left .opacity-bg {
      width: 100%;
      background: url(../images/for-patients-image.png);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: bottom right;
      position: absolute;
      mix-blend-mode: soft-light;
      left: 0;
      height: 300px; }
      body:not(.panels-ipe-editing) .wcm-thalassemia-home .middle-columns .col-md-6.middle-column-left .opacity-bg.opacity-mode {
        opacity: 0.4; }
      @media screen and (min-width: 992px) {
        body:not(.panels-ipe-editing) .wcm-thalassemia-home .middle-columns .col-md-6.middle-column-left .opacity-bg {
          height: 500px; } }
  body:not(.panels-ipe-editing) .wcm-thalassemia-home .middle-columns .col-md-6.middle-column-right {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#b31b1b+0,ffc72c+100 */
    background: #b31b1b;
    /* Old browsers */
    background: -moz-linear-gradient(-45deg, #b31b1b 0%, #ffc72c 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(-45deg, #b31b1b 0%, #ffc72c 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(135deg, #b31b1b 0%, #ffc72c 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#b31b1b', endColorstr='#ffc72c',GradientType=1 );
    /* IE6-9 fallback on horizontal gradient */ }
    @media screen and (min-width: 992px) {
      body:not(.panels-ipe-editing) .wcm-thalassemia-home .middle-columns .col-md-6.middle-column-right {
        border-left: 10px solid #fff;
        padding-left: 0; } }
    body:not(.panels-ipe-editing) .wcm-thalassemia-home .middle-columns .col-md-6.middle-column-right .hero-image--full {
      background-position: bottom left; }
    body:not(.panels-ipe-editing) .wcm-thalassemia-home .middle-columns .col-md-6.middle-column-right .pane-bundle-text {
      margin: 0 0 0 auto; }
    body:not(.panels-ipe-editing) .wcm-thalassemia-home .middle-columns .col-md-6.middle-column-right .opacity-bg {
      width: 100%;
      background: url(../images/for-providers-image.png);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: bottom left;
      position: absolute;
      mix-blend-mode: soft-light;
      left: 0;
      height: 300px; }
      body:not(.panels-ipe-editing) .wcm-thalassemia-home .middle-columns .col-md-6.middle-column-right .opacity-bg.opacity-mode {
        opacity: 0.4; }
      @media screen and (min-width: 992px) {
        body:not(.panels-ipe-editing) .wcm-thalassemia-home .middle-columns .col-md-6.middle-column-right .opacity-bg {
          height: 500px; } }
  body:not(.panels-ipe-editing) .wcm-thalassemia-home .middle-columns .col-md-6 .opacity {
    -webkit-transition: all 0.25s;
    -o-transition: all 0.25s;
    transition: all 0.25s;
    background: rgba(255, 255, 255, 0.5);
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    height: 100%;
    z-index: 1; }
    @media screen and (min-width: 992px) {
      body:not(.panels-ipe-editing) .wcm-thalassemia-home .middle-columns .col-md-6 .opacity {
        opacity: 0; } }
  body:not(.panels-ipe-editing) .wcm-thalassemia-home .middle-columns .col-md-6 .col-md-6-inner-left, body:not(.panels-ipe-editing) .wcm-thalassemia-home .middle-columns .col-md-6 .col-md-6-inner-right {
    width: auto; }
  body:not(.panels-ipe-editing) .wcm-thalassemia-home .middle-columns .col-md-6 .pane-bundle-text {
    padding: 40px 0px;
    height: 300px;
    margin: 0 auto 0 0;
    position: relative;
    z-index: 2; }
    @media screen and (min-width: 992px) {
      body:not(.panels-ipe-editing) .wcm-thalassemia-home .middle-columns .col-md-6 .pane-bundle-text {
        width: 60%;
        height: 500px; } }
    body:not(.panels-ipe-editing) .wcm-thalassemia-home .middle-columns .col-md-6 .pane-bundle-text .pane-title, body:not(.panels-ipe-editing) .wcm-thalassemia-home .middle-columns .col-md-6 .pane-bundle-text h3 {
      color: #333333;
      -webkit-transition: all 0.25s;
      -o-transition: all 0.25s;
      transition: all 0.25s;
      font-size: 18px;
      font-family: inherit;
      margin-top: 0;
      color: #555555;
      padding-bottom: 10px; }
      @media screen and (min-width: 992px) {
        body:not(.panels-ipe-editing) .wcm-thalassemia-home .middle-columns .col-md-6 .pane-bundle-text .pane-title, body:not(.panels-ipe-editing) .wcm-thalassemia-home .middle-columns .col-md-6 .pane-bundle-text h3 {
          color: #fff;
          padding-bottom: 0;
          font-size: 36px;
          font-family: "1898Sans-Bold", sans-serif; } }
    body:not(.panels-ipe-editing) .wcm-thalassemia-home .middle-columns .col-md-6 .pane-bundle-text .fieldable-panels-pane {
      position: relative;
      top: 50%;
      -webkit-transform: translateY(-50%);
      /* Ch <36, Saf 5.1+, iOS, An =<4.4.4 */
      -ms-transform: translateY(-50%);
      /* IE 9 */
      transform: translateY(-50%);
      /* IE 10, Fx 16+, Op 12.1+ */ }
    body:not(.panels-ipe-editing) .wcm-thalassemia-home .middle-columns .col-md-6 .pane-bundle-text .fieldable-panels-pane p {
      -webkit-transition: all 0.25s;
      -o-transition: all 0.25s;
      transition: all 0.25s;
      font-size: 20px;
      color: #cf4520; }
      @media screen and (min-width: 992px) {
        body:not(.panels-ipe-editing) .wcm-thalassemia-home .middle-columns .col-md-6 .pane-bundle-text .fieldable-panels-pane p {
          font-size: 26px;
          line-height: 1.5;
          max-height: 0;
          overflow: hidden; } }
  body:not(.panels-ipe-editing) .wcm-thalassemia-home .middle-columns .col-md-6:hover .opacity {
    opacity: 1; }
  body:not(.panels-ipe-editing) .wcm-thalassemia-home .middle-columns .col-md-6:hover .pane-title, body:not(.panels-ipe-editing) .wcm-thalassemia-home .middle-columns .col-md-6:hover h3 {
    font-family: inherit;
    font-size: 20px;
    color: #555555;
    padding-bottom: 10px; }
  body:not(.panels-ipe-editing) .wcm-thalassemia-home .middle-columns .col-md-6:hover .fieldable-panels-pane p {
    max-height: 1000px; }

.pane-news-panel-pane-3 {
  background: #f7f7f7;
  padding: 20px 0; }
  .pane-news-panel-pane-3:before, .pane-news-panel-pane-3:after {
    content: " ";
    display: table; }
  .pane-news-panel-pane-3:after {
    clear: both; }
  .pane-news-panel-pane-3 .pane-title {
    text-align: center;
    color: #666666;
    padding-bottom: 30px; }
  .pane-news-panel-pane-3 .view-content {
    margin: 0 auto;
    max-width: 1140px; }
    .pane-news-panel-pane-3 .view-content .teaser-image {
      margin-bottom: 20px; }
    .pane-news-panel-pane-3 .view-content .teaser-title {
      font-family: inherit; }
      .pane-news-panel-pane-3 .view-content .teaser-title a:after {
        content: ''; }
    .pane-news-panel-pane-3 .view-content .views-field-created:after {
      content: '';
      position: relative;
      width: 70px;
      display: block;
      margin: 10px auto;
      border-bottom: 2px solid #666666; }
  .pane-news-panel-pane-3 .view-footer {
    padding-top: 20px;
    clear: both;
    text-align: center; }
    .pane-news-panel-pane-3 .view-footer a:after {
      content: '\e80d';
      font-size: 80%;
      padding-left: 10px;
      font-family: "fontello";
      font-style: normal;
      font-weight: normal;
      speak: none;
      display: inline-block;
      text-decoration: inherit;
      text-align: center;
      /* opacity: .8; */
      /* For safety - reset parent styles, that can break glyph codes*/
      font-variant: normal;
      text-transform: none;
      /* fix buttons height, for twitter bootstrap */
      line-height: 1em;
      /* Animation center compensation - margins should be symmetric */
      /* remove if not needed */
      /* you can be more comfortable with increased icons size */
      /* font-size: 120%; */
      /* Font smoothing. That was taken from TWBS */
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      /* Uncomment for 3D effect */
      /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */ }

.view-news .view-teaser {
  border-bottom: 1px solid #dddddd;
  padding-bottom: 15px; }
  .front .view-news .view-teaser {
    border-bottom: none;
    text-align: center; }
  .view-news .view-teaser h2.teaser-title {
    font-size: 20px;
    line-height: 1.4; }
  .view-news .view-teaser .post-date {
    color: #666666;
    font-family: "1898Sans-Bold", sans-serif;
    text-transform: uppercase;
    margin-top: 5px;
    font-size: 13px;
    letter-spacing: 2px; }

.node-type-news-post .post-date {
  color: #666666;
  font-family: "1898Sans-Bold", sans-serif;
  text-transform: uppercase;
  font-size: 13px;
  letter-spacing: 2px; }

/*
.social-share {
  // margin: 15px 0;
  padding: 0;
}
.social-share li {
	display: inline-block;
	height: 30px;
	padding: 0 5px;
	font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0;
}

.social-share a {
  color: $wcm-bright-orange;
  &:before {
    font-size: 16px;
    @include fontello();
    @include v-center;
  }
  &:hover {
    color: $wcm-yellow;
    text-decoration: none;
  }
}

.social-share__facebook a {
  &:before {
    content:'\e804';
  }
}

.social-share__twitter a {
  &:before {
    content:'\e807';
  }
}

.social-share__linkedin a {
  &:before {
    content:'\e806';
  }
}
//*/
.field-name-field-news-category a, .views-field-field-news-category a {
  color: #cf4520;
  font-size: 14px;
  background: url(../images/tag.png) 0 0 no-repeat;
  background-size: 16px 16px;
  padding-left: 25px;
  position: relative; }

.teaser-image img.panopoly-image-quarter {
  float: none;
  margin: 0 0 0.25em 0;
  width: auto;
  max-width: none; }
  @media screen and (min-width: 768px) {
    .teaser-image img.panopoly-image-quarter {
      float: right;
      margin: 0.25em 0 1.3em 1em;
      width: 25%;
      max-width: 25%; } }

.view-guidelines .views-row .views-field-field-guideline-pdf {
  position: relative;
  border-bottom: 1px solid #dddddd;
  padding: 15px 0px 15px 0px; }
  .view-guidelines .views-row .views-field-field-guideline-pdf .file-icon {
    display: none; }
  .view-guidelines .views-row .views-field-field-guideline-pdf a {
    color: #000000;
    display: block;
    position: relative;
    z-index: 1; }
    .view-guidelines .views-row .views-field-field-guideline-pdf a:hover {
      color: #e7751d; }
  .view-guidelines .views-row .views-field-field-guideline-pdf .cta-icon {
    color: #cf4520;
    fill: #e7751d;
    width: 45px;
    height: 30px;
    position: absolute;
    right: 0;
    top: 15px;
    border-left: 1px solid #dddddd;
    padding-left: 15px; }

.view-guidelines .views-row .views-field-field-guideline-link {
  position: relative;
  border-bottom: 1px solid #dddddd;
  padding: 15px 0px 15px 0px; }
  .view-guidelines .views-row .views-field-field-guideline-link a {
    color: #000000;
    display: block;
    position: relative;
    z-index: 1; }
    .view-guidelines .views-row .views-field-field-guideline-link a:after {
      display: none; }
    .view-guidelines .views-row .views-field-field-guideline-link a:hover {
      color: #e7751d; }
  .view-guidelines .views-row .views-field-field-guideline-link:after {
    content: '\e811';
    position: absolute;
    right: 0;
    top: 15px;
    font-size: 30px;
    color: #cf4520;
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    speak: none;
    display: inline-block;
    text-decoration: inherit;
    text-align: center;
    /* opacity: .8; */
    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;
    /* fix buttons height, for twitter bootstrap */
    line-height: 1em;
    /* Animation center compensation - margins should be symmetric */
    /* remove if not needed */
    /* you can be more comfortable with increased icons size */
    /* font-size: 120%; */
    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* Uncomment for 3D effect */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
    border-left: 1px solid #dddddd;
    padding-left: 15px; }

.view-education-material {
  margin-top: 40px; }
  .view-education-material .views-row {
    border-bottom: 1px solid #dddddd;
    padding-bottom: 20px; }
  .view-education-material .education_material_post_info {
    color: #666666;
    font-family: "1898Sans-Bold", sans-serif;
    text-transform: uppercase;
    margin-top: 5px;
    font-size: 13px;
    letter-spacing: 2px;
    /*.post-date {
			color: $wcm-med-gray;
			font-family: $wcm-bold;
			text-transform: uppercase;
			margin-top: 5px;
			font-size: 13px;
			letter-spacing: 2px;
		}*/ }
    .view-education-material .education_material_post_info .author-name {
      font-size: 15px;
      text-transform: none;
      font-family: inherit; }
  .view-education-material .views-field-field-education-material-pdf .file-icon {
    display: none; }
  .view-education-material .views-field-field-education-material-pdf a:before {
    content: '';
    display: inline-block;
    background-repeat: no-repeat;
    background: url(../../../../../../profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/sprite/sprite.svg);
    width: 30px;
    height: 30px;
    background-position: -37px -113px;
    background-size: 592px 143px;
    margin-right: 5px; }

.view-webinars {
  margin-top: 40px; }
  .view-webinars .views-row {
    border-bottom: 1px solid #dddddd;
    padding-bottom: 20px; }
    .view-webinars .views-row .views-field-field-webinar-date {
      color: #666666;
      font-family: "1898Sans-Bold", sans-serif;
      text-transform: uppercase;
      margin-top: 5px;
      font-size: 13px;
      letter-spacing: 2px; }

.pane-profiles-panel-pane-3 .view-profiles {
  margin-top: 40px; }
  .pane-profiles-panel-pane-3 .view-profiles .views-row {
    border-bottom: 1px solid #dddddd;
    padding-bottom: 20px;
    padding-top: 20px;
    /*.col-md-10 {
				padding-left: 0;
			}*/ }
    .pane-profiles-panel-pane-3 .view-profiles .views-row .col-md-2 {
      padding-left: 0; }
    .pane-profiles-panel-pane-3 .view-profiles .views-row .col-md-2-no-image + .col-md-10 {
      padding-left: 0; }
    .pane-profiles-panel-pane-3 .view-profiles .views-row .display-name {
      font-size: 15px;
      margin-top: 0; }
    .pane-profiles-panel-pane-3 .view-profiles .views-row .views-field-field-biographical-info {
      padding-top: 20px; }

.pane-wcmc-second-level-nav {
  clear: both; }

.pane-clinical-trials-panel-pane-2 .view-clinical-trials .views-row {
  border-bottom: none;
  padding-bottom: 0; }

.view-clinical-trials .views-row {
  border-bottom: 1px solid #dddddd;
  padding-bottom: 20px;
  margin-bottom: 15px; }
  .view-clinical-trials .views-row .views-field-title, .view-clinical-trials .views-row .trial-title {
    padding-bottom: 10px;
    font-size: 17px; }
  .view-clinical-trials .views-row .views-field-field-trial-primary-investigator,
  .view-clinical-trials .views-row .views-field-field-trial-contact-email,
  .view-clinical-trials .views-row .views-field-field-trial-contact-name,
  .view-clinical-trials .views-row .views-field-field-trial-contact-phone,
  .view-clinical-trials .views-row .views-field {
    padding-bottom: 2px; }
    .view-clinical-trials .views-row .views-field-field-trial-primary-investigator .views-label,
    .view-clinical-trials .views-row .views-field-field-trial-contact-email .views-label,
    .view-clinical-trials .views-row .views-field-field-trial-contact-name .views-label,
    .view-clinical-trials .views-row .views-field-field-trial-contact-phone .views-label,
    .view-clinical-trials .views-row .views-field .views-label {
      font-weight: 600; }
    .view-clinical-trials .views-row .views-field-field-trial-primary-investigator .field-content,
    .view-clinical-trials .views-row .views-field-field-trial-contact-email .field-content,
    .view-clinical-trials .views-row .views-field-field-trial-contact-name .field-content,
    .view-clinical-trials .views-row .views-field-field-trial-contact-phone .field-content,
    .view-clinical-trials .views-row .views-field .field-content {
      display: inline; }
  .view-clinical-trials .views-row .views-field-nothing {
    padding-top: 10px; }

.view-clinical-trials a.pc-btn-small {
  display: inline-block;
  background-color: #cf4520;
  border-radius: 7px;
  height: 30px;
  line-height: 30px;
  padding: 0 14px;
  color: #fff;
  margin-right: 10px;
  font-size: 13px; }

.page-research-publications.page-research-publications-posters h1.title {
  padding-left: 0 !important;
  padding-right: 0 !important; }

.page-research-publications .site-main {
  margin-left: auto;
  margin-right: auto; }
  .page-research-publications .site-main h1.title, .page-research-publications .site-main .biblio-category-section, .page-research-publications .site-main #biblio-header {
    padding-left: 20px;
    padding-right: 20px; }
  .page-research-publications .site-main #biblio-header a {
    padding-left: 10px;
    padding-right: 10px; }
    .page-research-publications .site-main #biblio-header a:first-child {
      padding-left: 0; }
  .page-research-publications .site-main #biblio-header img {
    margin-left: -10px;
    padding-right: 10px;
    vertical-align: middle; }
  @media screen and (min-width: 768px) {
    .page-research-publications .site-main {
      width: 768px; } }
  @media screen and (min-width: 992px) {
    .page-research-publications .site-main {
      width: 980px; } }
  @media screen and (min-width: 1200px) {
    .page-research-publications .site-main {
      width: 1180px; } }

.pager--infinite-scroll li a {
  border-radius: 20px;
  border: 4px solid #e7751d;
  padding: 6px 20px;
  font-size: 13px;
  width: 220px; }
  .pager--infinite-scroll li a:hover, .pager--infinite-scroll li a:focus {
    border-color: #cf4520;
    background-color: #e7751d;
    color: #fff; }

.view-testimonials {
  clear: both; }
  .view-testimonials .views-row {
    border-bottom: 1px solid #dddddd;
    padding-bottom: 20px;
    margin-bottom: 20px; }
    .view-testimonials .views-row:before, .view-testimonials .views-row:after {
      content: " ";
      display: table; }
    .view-testimonials .views-row:after {
      clear: both; }
    .view-testimonials .views-row:last-child {
      border-bottom: none; }
    .view-testimonials .views-row .no-image + .col-sm-8 {
      width: 100%;
      padding-left: 0; }
    .view-testimonials .views-row .col-sm-4 {
      margin-bottom: 15px;
      padding-left: 0; }
      @media screen and (max-width: 767px) {
        .view-testimonials .views-row .col-sm-4 {
          padding-right: 0; } }
      .view-testimonials .views-row .col-sm-4 img {
        margin-bottom: 5px; }
        @media screen and (min-width: 768px) {
          .view-testimonials .views-row .col-sm-4 img {
            width: 100%; } }
      .view-testimonials .views-row .col-sm-4 figcaption {
        text-align: left;
        float: none;
        width: auto; }
    .view-testimonials .views-row .col-sm-8 {
      padding-right: 0; }
      @media screen and (max-width: 767px) {
        .view-testimonials .views-row .col-sm-8 {
          padding-left: 0; } }

.pane-bundle-spotlight .panopoly-image-spotlight {
  width: 100%; }

.wcm-callout-link:hover {
  text-decoration: none; }

.wcm-callout {
  position: relative;
  margin-top: 30px;
  margin-bottom: 30px; }
  .wcm-callout .wcm-callout-title {
    padding: 50px 20px 50px;
    text-transform: uppercase;
    color: #fff;
    text-align: center;
    z-index: 10;
    font-weight: 600;
    letter-spacing: 2px; }
    @media screen and (max-width: 767px) {
      .wcm-callout .wcm-callout-title {
        padding: 30px 20px 30px; } }
  .wcm-callout.wcm-red {
    background: #b31b1b; }
    .wcm-callout.wcm-red:hover {
      background: #e7751d; }
  .wcm-callout.wcm-dark-orange {
    background: #cf4520; }
    .wcm-callout.wcm-dark-orange:hover {
      background: #e7751d; }
  .wcm-callout.wcm-bright-orange {
    background: #e7751d; }
    .wcm-callout.wcm-bright-orange:hover {
      background: #ffc72c; }
  .wcm-callout.wcm-yellow {
    background: #ffc72c; }
    .wcm-callout.wcm-yellow:hover {
      background: #e7751d; }

.footer-contact__contact-us a {
  /*@include breakpoint($md) {
        padding: 20px 28px;
    }*/ }
  @media screen and (min-width: 768px) and (max-width: 991px) {
    .footer-contact__contact-us a {
      padding: 20px 20px; } }

.media-gallery-callout {
  clear: both;
  border-top: 1px solid #eee; }

@media screen and (min-width: 768px) {
  #drawer-nav ul li.level-1:nth-child(4) ul li.level-2 {
    padding-right: 15px; } }

@media screen and (min-width: 992px) {
  #drawer-nav ul li.level-1:nth-child(4) ul li.level-2 {
    padding-right: 10px; } }

#webform-client-form-62 fieldset {
  border: 0;
  border-radius: 0;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  margin-bottom: 0; }
  #webform-client-form-62 fieldset .panel-body {
    padding: 5px 0 0 0; }
